/* eslint-disable jsx-a11y/anchor-is-valid */
import * as nearAPI from 'near-api-js';
import { useWallet } from '../../hooks';
import { DEFAULT_NETWORK, NETWORKS } from '../../config';
import { WalletStatus } from '../../constants';
import nearLogo from '../../assets/icons/near.png';
import './Navbar.css';

const formatAddress = (value) => {
  if (!value) {
    return '';
  }

  if (value.includes('.')) {
    return value;
  }

  return `${value.slice(0, 15)}...`;
};

const formatBalance = (value) => {
  let formattedValue = '0.00';
  if (value) {
    formattedValue = Number(
      nearAPI.utils.format.formatNearAmount(value)
    ).toFixed(5);
  }
  return `${formattedValue} NEAR`;
};

const Navbar = () => {
  const {
    accountId,
    balance,
    status,
    networkId,
    connect,
    disconnect,
    onNetworkChange,
  } = useWallet();

  const connectHandler = async () => {
    await connect();
  };

  const disconnectHandler = async () => {
    await disconnect();
  };

  const changeNetworkHandler = async (e) => {
    const newNetworkId = e.target.value;
    await onNetworkChange(newNetworkId);
  };

  return (
    <nav className="navbar navbar-dark navbar-custom">
      <a href="/" className="navbar-brand" onClick={(e) => e.preventDefault()}>
        Near Demo
      </a>

      <form className="form-inline">
        <select
          className="custom-select mr-4"
          onChange={changeNetworkHandler}
          value={networkId}
        >
          {Object.values(NETWORKS).map((network) => (
            <option
              key={network.networkId}
              value={network.networkId}
              disabled={network.networkId !== DEFAULT_NETWORK.networkId}
            >
              {network.name}
            </option>
          ))}
        </select>

        {status === WalletStatus.connecting && (
          <button
            type="button"
            className="btn btn-light d-flex align-items-center"
            onClick={() => {}}
          >
            <div
              className="spinner-border spinner-border-sm mr-2"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
            Loading...
          </button>
        )}

        {status === WalletStatus.disconnected && (
          <button
            type="button"
            className="btn btn-light d-flex align-items-center"
            onClick={connectHandler}
          >
            <img
              height="22px"
              width="22px"
              className="mr-2"
              src={nearLogo}
              alt="near"
            />
            Connect
          </button>
        )}

        {status === WalletStatus.connected && (
          <>
            <div className="mr-4 text-white account">
              {formatBalance(balance)}
            </div>

            <div className="mr-4 text-white account">
              {formatAddress(accountId)}
            </div>

            <button
              type="button"
              className="btn btn-light"
              onClick={disconnectHandler}
            >
              <img
                height="22px"
                width="22px"
                className="mr-2"
                src={nearLogo}
                alt="near"
              />
              Disconnect
            </button>
          </>
        )}
      </form>
    </nav>
  );
};

export default Navbar;
