import { NEAR_MAINNET_NETWORK, NEAR_TEST_NETWORK } from './networks';

const MAINNET_VERIFIER_CONTRACT_NAME =
  process.env.REACT_APP_MAINNET_VERIFIER_CONTRACT_NAME || '';
const MAINNET_BUYER_CONTRACT_NAME =
  process.env.REACT_APP_MAINNET_BUYER_CONTRACT_NAME || '';
const TESTNET_VERIFIER_CONTRACT_NAME =
  process.env.REACT_APP_TESTNET_VERIFIER_CONTRACT_NAME || '';
const TESTNET_BUYER_CONTRACT_NAME =
  process.env.REACT_APP_TESTNET_BUYER_CONTRACT_NAME || '';

const SUCCESS_RETURN_URL = process.env.REACT_APP_SUCCESS_RETURN_URL || '';
const FAILURE_RETURN_URL = process.env.REACT_APP_FAILURE_RETURN_URL || '';

const MAINNET_CONFIG = {
  networkId: NEAR_MAINNET_NETWORK.networkId,
  nodeUrl: NEAR_MAINNET_NETWORK.nodeUrl,
  walletUrl: NEAR_MAINNET_NETWORK.walletUrl,
  helperUrl: NEAR_MAINNET_NETWORK.helperUrl,
  verifierContractName: MAINNET_VERIFIER_CONTRACT_NAME,
  buyerContractName: MAINNET_BUYER_CONTRACT_NAME,
  successReturnUrl: SUCCESS_RETURN_URL,
  failureReturnUrl: FAILURE_RETURN_URL,
};

const TESTNET_CONFIG = {
  networkId: NEAR_TEST_NETWORK.networkId,
  nodeUrl: NEAR_TEST_NETWORK.nodeUrl,
  walletUrl: NEAR_TEST_NETWORK.walletUrl,
  helperUrl: NEAR_TEST_NETWORK.helperUrl,
  verifierContractName: TESTNET_VERIFIER_CONTRACT_NAME,
  buyerContractName: TESTNET_BUYER_CONTRACT_NAME,
  successReturnUrl: SUCCESS_RETURN_URL,
  failureReturnUrl: FAILURE_RETURN_URL,
};

const CONFIG = {
  [MAINNET_CONFIG.networkId]: MAINNET_CONFIG,
  [TESTNET_CONFIG.networkId]: TESTNET_CONFIG,
};

export default CONFIG;
