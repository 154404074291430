import * as nearAPI from 'near-api-js';
import { CONFIG } from '../config';

const KEY_STORE_PREFIX = 'KEY_STORE_PREFIX';
const APP_KEY_PREFIX = 'APP_KEY_PREFIX';

class Near {
  keyStore = null;
  networkId = null;
  nearConnection = null;
  walletConnection = null;
  accountId = null;

  constructor() {
    this.keyStore = new nearAPI.keyStores.BrowserLocalStorageKeyStore(
      window.localStorage,
      KEY_STORE_PREFIX
    );
  }

  _checkIfSignedIn = () => {
    return this.walletConnection?.isSignedIn();
  };

  init = async (networkId) => {
    const currentConfig = CONFIG[networkId];
    const nearConfig = {
      ...currentConfig,
      keyStore: this.keyStore,
    };

    const nearConnection = await nearAPI.connect(nearConfig);

    const walletConnection = new nearAPI.WalletConnection(
      nearConnection,
      `${APP_KEY_PREFIX}_${networkId}`
    );

    this.networkId = networkId;
    this.nearConnection = nearConnection;
    this.walletConnection = walletConnection;
  };

  connect = async (isInitialization) => {
    const isSignedIn = this._checkIfSignedIn();

    if (!isSignedIn && !isInitialization) {
      this.walletConnection?.requestSignIn({
        contractId: this.nearConnection?.config.buyerContractName,
        methodNames: [],
        successUrl: this.nearConnection?.config.successReturnUrl,
        failureUrl: this.nearConnection?.config.failureReturnUrl,
      });
    } else {
      const accountId = this.walletConnection?.getAccountId();

      if (!accountId) {
        this.accountId = null;
        return null;
      }

      const account = await this.walletConnection?.account();
      const accountState = await account.state();
      const balance = accountState.amount;

      const accountData = {
        accountId,
        balance,
      };

      this.accountId = accountId;
      return accountData;
    }
  };

  disconnect = async () => {
    await this.walletConnection?.signOut();
  };

  getSigner = () => {
    const self = this;
    const signer = {
      signMessage: async (uint8Message) => {
        const keyPair = await self.keyStore?.getKey(
          self.networkId,
          self.accountId
        );
        const signResult = keyPair.sign(uint8Message);
        return signResult;
      },
      getAddress: async () => {
        Promise.resolve(self.accountId);
      },
    };

    return signer;
  };

  signMessage = async (message) => {
    const keyPair = await this.keyStore?.getKey(this.networkId, this.accountId);
    const bufferedMessage = Buffer.from(message);
    const uint8Message = new Uint8Array(bufferedMessage);
    const signResult = keyPair.sign(uint8Message);
    Object.assign(signResult, { message: uint8Message });
    return signResult;
  };

  buy = async (ruleType, data, signature, v, to, value) => {
    const account = await this.walletConnection?.account();

    const buyerContract = new nearAPI.Contract(
      account,
      this.nearConnection?.config.buyerContractName,
      {
        viewMethods: [],
        changeMethods: [
          'buy_for_with_aml_check',
          'buy_for_with_kyc_check',
          'buy_for_with_kyc_aml_check',
        ],
      }
    );

    const amount = nearAPI.utils.format.parseNearAmount(value.toString());
    const defaultGas = '100000000000000';

    if (ruleType === 0) {
      await buyerContract.buy_for_with_aml_check({
        args: {
          data,
          signature,
          v,
          to,
        },
        amount,
        gas: defaultGas,
      });
    } else if (ruleType === 1) {
      await buyerContract.buy_for_with_kyc_check({
        args: {
          data,
          signature,
          v,
          to,
        },
        amount,
        gas: defaultGas,
      });
    } else if (ruleType === 2) {
      await buyerContract.buy_for_with_kyc_aml_check({
        args: {
          data,
          signature,
          v,
          to,
        },
        amount,
        gas: defaultGas,
      });
    }

    throw new Error('Unknown rule type provided');
  };

  getTransactionResult = async (txnHash) => {
    const transaction = await this.nearConnection?.provider.txStatus(
      txnHash,
      this.accountId
    );
    const result = nearAPI.providers.getTransactionLastResult(transaction);
    return result;
  };

  verify = async (data, signature, v) => {
    const account = await this.walletConnection.account();

    const verifierContract = new nearAPI.Contract(
      account,
      this.nearConnection.config.verifierContractName,
      {
        viewMethods: [],
        changeMethods: ['verify_issuer_signature'],
      }
    );

    const response = await verifierContract.verify_issuer_signature({
      args: {
        data,
        signature,
        v,
      },
    });
    return response;
  };

  // async function (account_id, private_key, attached_tokens, attached_gas, recipient, method, params, network, rpc_node, headers) {
  //   try {
  //       const account = await this.GetAccountByKey(account_id, private_key, network, rpc_node, headers);

  //       return await account.functionCall({
  //           contractId: recipient,
  //           methodName: method,
  //           args: params,
  //           gas: attached_gas,
  //           attachedDeposit: attached_tokens
  //       });
  //   } catch (e) {
  //       console.log(e);
  //       return api.reject(e);
  //   }
  // };
}

const near = new Near();

export default near;
