import { createContext } from 'react';
import { DEFAULT_NETWORK } from '../config';
import { WalletStatus } from '../constants';

const WalletContext = createContext({
  accountId: '',
  balance: '',
  networkId: DEFAULT_NETWORK.networkId,
  status: WalletStatus.disconnected,
  connect: () => {},
  disconnect: () => {},
  onNetworkChange: () => {},
});

export default WalletContext;
