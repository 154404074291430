import nearLogo from '../assets/icons/near.png';

const NEAR_MAINNET_NETWORK = {
  networkId: 'mainnet',
  nodeUrl: 'https://rpc.mainnet.near.org',
  walletUrl: 'https://wallet.near.org',
  helperUrl: 'https://helper.mainnet.near.org',
  name: 'NEAR MAINNET',
  symbol: 'NEAR',
  avatar: nearLogo,
};

const NEAR_TEST_NETWORK = {
  networkId: 'testnet',
  nodeUrl: 'https://rpc.testnet.near.org',
  walletUrl: 'https://wallet.testnet.near.org',
  helperUrl: 'https://helper.testnet.near.org',
  name: 'NEAR TESTNET',
  symbol: 'NEAR',
  avatar: nearLogo,
};

const NETWORKS = {
  [NEAR_MAINNET_NETWORK.networkId]: NEAR_MAINNET_NETWORK,
  [NEAR_TEST_NETWORK.networkId]: NEAR_TEST_NETWORK,
};

const DEFAULT_NETWORK = NEAR_TEST_NETWORK;
// const DEFAULT_NETWORK = NEAR_MAINNET_NETWORK;

export { NETWORKS, NEAR_MAINNET_NETWORK, NEAR_TEST_NETWORK, DEFAULT_NETWORK };
