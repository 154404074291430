import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { WalletStatus } from '../constants';
import WalletContext from './WalletContext';
import { DEFAULT_NETWORK } from '../config';
import near from '../near';

const WalletProvider = (props) => {
  const { children } = props;
  const queryClient = useQueryClient();

  const [accountId, setAccountId] = useState('');
  const [balance, setBalance] = useState('');
  const [networkId, setNetworkId] = useState(DEFAULT_NETWORK.networkId);
  const [status, setStatus] = useState(WalletStatus.connecting);

  const refetchQueries = useCallback(async () => {
    await queryClient.invalidateQueries();
  }, [queryClient]);

  const connect = useCallback(
    async (isInitialization = false) => {
      try {
        const accountData = await near.connect(isInitialization);
        if (accountData !== null) {
          setAccountId(accountData.accountId);
          setBalance(accountData.balance);
          setStatus(WalletStatus.connected);
        } else {
          setAccountId('');
          setBalance('');
          setStatus(WalletStatus.disconnected);
        }
      } catch (e) {
        console.log(e);
        setAccountId('');
        setBalance('');
        setStatus(WalletStatus.disconnected);
      }
    },
    [setAccountId, setBalance, setStatus]
  );

  const disconnect = useCallback(async () => {
    try {
      await near.disconnect();
    } catch (e) {
      console.log(e);
    }
    setAccountId('');
    setBalance('');
    setStatus(WalletStatus.disconnected);
  }, [setAccountId, setBalance, setStatus]);

  const handleNetworkChange = useCallback(
    async (newNetworkId) => {
      try {
        setNetworkId(newNetworkId);
        await near.init(newNetworkId);
        await connect();
        await refetchQueries();
      } catch (e) {
        console.log(e);
        setAccountId('');
        setBalance('');
        setStatus(WalletStatus.disconnected);
      }
    },
    [setNetworkId, setAccountId, setBalance, setStatus, connect, refetchQueries]
  );

  useEffect(() => {
    const init = async () => {
      await near.init(networkId);
      await connect(true);
    };
    init();
  }, []);

  return (
    <WalletContext.Provider
      value={{
        accountId,
        balance,
        networkId,
        status,
        connect,
        disconnect,
        onNetworkChange: handleNetworkChange,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
