import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import near from '../near';

const useBuyContract = () => {
  const queryClient = useQueryClient();
  const [error, setError] = useState('');

  const refetchQuery = useCallback(() => {
    queryClient.invalidateQueries();
  }, [queryClient]);

  const handleError = useCallback((error) => {
    setError(`${error.type}: ${error.message}`);
  }, []);

  const handleSuccess = useCallback(() => {
    refetchQuery();
  }, [refetchQuery]);

  const handleTransaction = useCallback(async (mutationFunc) => {
    await mutationFunc;
  }, []);

  const buy = useMutation(
    async (variables) => {
      const { data, signature, v, to, value, ruleType } = variables;
      setError('');
      return handleTransaction(
        near.buy(ruleType, data, signature, v, to, value)
      );
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  return {
    loading: buy.isLoading,
    action: buy.mutateAsync,
    error: error,
    clearError: useCallback(() => {
      setError('');
    }, [setError]),
  };
};

export default useBuyContract;
