export const WalletStatus = Object.freeze({
  connecting: 'connecting',
  connected: 'connected',
  disconnected: 'disconnected',
});

export const WalletType = Object.freeze({
  near: 'near',
  none: '',
});
