import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PureFI } from '@purefi/verifier-sdk';
import Button from 'react-bootstrap/Button';
import { useWallet, useBuyContract } from '../../hooks';
import { WalletStatus } from '../../constants';
import near from '../../near';
import { Toggle } from '../Toggle';
import goSrc from '../../assets/icons/action-go.svg';

const ruleTypeOptions = [
  {
    label: 'AML',
    value: 0,
  },
  {
    label: 'KYC',
    value: 1,
  },
  {
    label: 'AML + KYC',
    value: 2,
  },
];

const defaultRuleType = ruleTypeOptions[0].value;

const ruleType2RuleId = {
  0: '431040',
  1: '777',
  2: '731040',
};

const TheForm = () => {
  const signFormRef = useRef();
  const buyFormRef = useRef();

  const [txnHash, setTxnHash] = useState('');
  const { accountId: currentUserAccountId, status, networkId } = useWallet();

  const {
    loading: buyLoading,
    action: buy,
    error: buyError,
    clearError: clearBuyError,
  } = useBuyContract();

  // const {
  //   loading: verifyLoading,
  //   action: verify,
  //   data: isVerified,
  //   error: verifyError,
  //   clear: clearVerifyError,
  // } = useVerifySignature();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams) {
      const transactionHashes = searchParams.get('transactionHashes');
      if (transactionHashes !== null) {
        let hash;
        if (typeof transactionHashes === 'string') {
          hash = transactionHashes;
        } else if (
          Array.isArray(transactionHashes) &&
          transactionHashes.length > 0
        ) {
          hash = transactionHashes[0];
        }
        searchParams.delete('transactionHashes');
        setTxnHash(hash || '');
      }

      const errorMessage = searchParams.get('errorMessage');
      if (errorMessage !== null) {
        let message = 'Unexpected error occurred';
        if (typeof errorMessage === 'string') {
          message = decodeURIComponent(errorMessage);
        }
        searchParams.delete('errorMessage');
        searchParams.delete('errorCode');
        setError(message);
      }

      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const [accountId, setAccountId] = useState('');
  const [ruleType, setRuleType] = useState(defaultRuleType);
  const [ruleId, setRuleId] = useState(ruleType2RuleId[defaultRuleType]);
  const [clientMessage, setClientMessage] = useState('');
  const [clientSignature, setClientSignature] = useState('');
  const [clientPublicKey, setClientPublicKey] = useState('');
  const [signLoading, setSignLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [responseData, setResponseData] = useState();
  const [responseSignature, setResponseSignature] = useState('');
  const [responseV, setResponseV] = useState('');
  const [nearValue, setNearValue] = useState('1');

  const [error, setError] = useState('');

  useEffect(() => {
    setAccountId(currentUserAccountId);
    setRuleType(defaultRuleType);
    setClientMessage('');
    setClientSignature('');
    setClientPublicKey('');
  }, [currentUserAccountId]);

  useEffect(() => {
    setRuleId(ruleType2RuleId[ruleType]);
    setClientMessage('');
    setClientSignature('');
    setClientPublicKey('');
  }, [ruleType]);

  useEffect(() => {
    if (currentUserAccountId && networkId) {
      const signer = near.getSigner();
      PureFI.setSigner(signer);
    } else {
      PureFI.unsetSigner();
    }
  }, [currentUserAccountId, networkId]);

  const loading = signLoading || requestLoading || buyLoading;

  const dummyChangeHandler = () => {};

  const checkValidity = (theRef) => {
    const isValid = theRef.current.checkValidity();
    if (!isValid) {
      theRef.current.reportValidity();
    }
    return isValid;
  };

  const ruleChangeHandler = (e) => {
    if (!loading) {
      setRuleType(+e.target.value);
    }
  };

  const signMessageHandler = async (e) => {
    const isValid = checkValidity(signFormRef);

    if (isValid) {
      try {
        setSignLoading(true);

        const dataToSign = {
          address: accountId,
          ruleId,
          chainId: 'near',
        };

        const message = JSON.stringify(dataToSign);
        const bufferedMessage = Buffer.from(message);
        const uint8Message = new Uint8Array(bufferedMessage);

        const signer = PureFI.getSigner();
        const signResult = await signer.signMessage(uint8Message);

        setClientMessage(uint8Message);
        setClientSignature(signResult.signature);
        setClientPublicKey(signResult.publicKey);
      } catch (error) {
        setError(error.message);
      } finally {
        setSignLoading(false);
      }
    }
  };

  const submitHandler = async (e) => {
    try {
      setRequestLoading(true);

      const payload = {
        message: clientMessage,
        signature: clientSignature,
        publicKey: clientPublicKey,
      };

      const response = await PureFI.verifyRule(payload);

      setError('');
      setResponseData(response.data);
      setResponseSignature(response.signature);
      setResponseV(response.v);
    } catch (error) {
      setResponseData('');
      setResponseSignature('');
      setResponseV('');
      setError(error.message);
    } finally {
      setRequestLoading(false);
    }
  };

  const buyHandler = async (e) => {
    const isValid = checkValidity(buyFormRef);
    if (isValid) {
      const payload = {
        data: responseData,
        signature: responseSignature,
        v: responseV,
        to: accountId,
        value: nearValue,
        ruleType,
      };
      setTxnHash('');
      await buy(payload);
    }
  };

  // const verifySignatureHandler = async (e) => {
  //   const isValid = checkValidity(buyFormRef);
  //   if (isValid) {
  //     const payload = {
  //       data: responseData,
  //       signature: responseSignature,
  //       v: responseV,
  //     };
  //     await verify(payload);
  //   }
  // };

  // const loading = signLoading || requestLoading || buyLoading || verifyLoading;

  if (status === WalletStatus.connecting) {
    return null;
  }

  if (status === WalletStatus.disconnected) {
    return (
      <Container fluid className="mb-4">
        <div className="alert alert-primary mb-2" role="alert">
          Connect account to proceed
        </div>
      </Container>
    );
  }

  return (
    <Container fluid className="mb-4">
      {error && (
        <div className="alert alert-danger mb-3" role="alert">
          {error}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setError('')}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}

      {buyError && (
        <div className="alert alert-danger mb-3" role="alert">
          {buyError}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => clearBuyError()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}

      {/* {verifyError && (
        <div className="alert alert-danger mb-3" role="alert">
          {verifyError}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => clearVerifyError()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )} */}

      <Row>
        <Col xs={12} md={4}>
          <h4 className="mb-4">Input Data</h4>
          <Form ref={signFormRef}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="accountId">Target Account</Form.Label>
              <Form.Control
                type="text"
                id="accountId"
                name="accountId"
                value={accountId}
                onChange={dummyChangeHandler}
                readOnly
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="ruleType">Rule Type</Form.Label>
              <Toggle
                name="ruleType"
                value={ruleType}
                options={ruleTypeOptions}
                onChange={ruleChangeHandler}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="ruleId">Rule Id</Form.Label>
              <Form.Control
                type="text"
                id="ruleId"
                name="ruleId"
                value={ruleId}
                onChange={dummyChangeHandler}
                readOnly
                required
              />
            </Form.Group>
            <Form.Group>
              <Button
                variant="secondary"
                type="button"
                onClick={signMessageHandler}
                disabled={loading || !accountId || !ruleId}
                block
              >
                Sign Message
              </Button>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="clientMessage">Message</Form.Label>
              <Form.Control
                id="clientMessage"
                as="textarea"
                rows={4}
                value={clientMessage ? clientMessage.toString() : ''}
                onChange={dummyChangeHandler}
                required
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="clientSignature">Signature</Form.Label>
              <Form.Control
                id="clientSignature"
                as="textarea"
                rows={4}
                value={clientSignature ? clientSignature.toString() : ''}
                onChange={dummyChangeHandler}
                required
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="piblicKey">Public key</Form.Label>
              <Form.Control
                id="piblicKey"
                as="textarea"
                rows={4}
                value={clientPublicKey ? clientPublicKey.toString() : ''}
                onChange={dummyChangeHandler}
                required
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Button
                variant="secondary"
                type="button"
                onClick={submitHandler}
                disabled={
                  loading ||
                  !clientMessage ||
                  !clientSignature ||
                  !clientPublicKey
                }
                block
              >
                Submit request
              </Button>
            </Form.Group>
          </Form>
        </Col>

        <Col xs={12} md={4}>
          <h4 className="mb-4">Issuer Response</h4>
          <Form ref={buyFormRef}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="nearValue">Buy for NEAR</Form.Label>
              <Form.Control
                id="nearValue"
                type="number"
                value={nearValue}
                onChange={(e) => setNearValue(e.target.value)}
                min="0.001"
                step="0.001"
                placeholder="0.01"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="responseData">Data</Form.Label>
              <Form.Control
                id="responseData"
                as="textarea"
                rows={4}
                value={
                  responseData ? JSON.stringify(responseData, undefined, 2) : ''
                }
                onChange={dummyChangeHandler}
                required
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="responseSignature">Signature</Form.Label>
              <Form.Control
                id="responseSignature"
                as="textarea"
                rows={4}
                value={responseSignature}
                onChange={dummyChangeHandler}
                required
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="responseV">V</Form.Label>
              <Form.Control
                id="responseV"
                value={responseV}
                onChange={dummyChangeHandler}
                required
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Button
                variant="secondary"
                type="button"
                onClick={buyHandler}
                disabled={
                  loading ||
                  !responseData ||
                  !responseSignature ||
                  responseV === ''
                }
                block
              >
                Buy
              </Button>
            </Form.Group>

            {/* <Form.Group className="mb-3">
              <Button
                variant="secondary"
                type="button"
                onClick={verifySignatureHandler}
                disabled={
                  loading ||
                  !responseData ||
                  !responseSignature ||
                  responseV === ''
                }
                block
                style={{
                  background: isVerified ? 'green' : '#6c757d',
                }}
              >
                {!isVerified ? 'Verify issuer signature' : 'Verified'}
              </Button>
            </Form.Group> */}
          </Form>
        </Col>

        <Col xs={12} md={4}>
          <h4 className="mb-4">Buy Contract Response</h4>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="txnHash">Transaction hash</Form.Label>
              <Form.Control
                id="txnHash"
                type="text"
                value={txnHash}
                onChange={dummyChangeHandler}
                readOnly
              />
            </Form.Group>
            {txnHash && (
              <Form.Group className="mb-3 d-flex align-items-center">
                <a
                  className="text-truncate"
                  href={`https://explorer.${networkId}.near.org/transactions/${txnHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: '#25A2E9',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <span className="mr-2">Near Explorer</span>
                  <img src={goSrc} height="12px" alt="go" />
                </a>
              </Form.Group>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default TheForm;
